let Fuse = require('fuse.js');

// Hack to make this work with both ES6 imports and CommonJS requires
Fuse = Fuse.default || Fuse;

const DEFAULT_OPTIONS = {
  threshold: 0.4,
  ignoreLocation: true,
};

const SITEWIDE_SEARCH_OPTIONS = {
  threshold: 0.2,
  ignoreLocation: true,
  includeMatches: true,
};

class Searcher {
  constructor(items, keys, customOptions = {}) {
    const options = {...DEFAULT_OPTIONS, ...customOptions};

    this.searcher = new Fuse(items, {
      ...options,
      keys,
    });

    this.unfilteredResults = items.map((doc, idx) => ({
      item: doc,
      score: 1,
      refIndex: idx,
    }));
  }

  setCollection(items) {
    this.searcher.setCollection(items);

    this.unfilteredResults = items.map((doc, idx) => ({
      item: doc,
      score: 1,
      refIndex: idx,
    }));
  }

  search(query, options) {
    return query ? this.searcher.search(query, options) : this.unfilteredResults;
  }
}

module.exports = {
  Searcher,
  DEFAULT_OPTIONS,
  SITEWIDE_SEARCH_OPTIONS,
};
