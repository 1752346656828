import React from 'react';
import {Formik, Form as FormikForm} from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

import CompanyFields from '../CompanySelect';
import SubmitButton from './SubmitButton';
import {useAnalytics} from '../../hooks';

const key = 'companies';
const title = 'What companies are you interested in?';

const Schema = yup.object().shape({
  companies: yup.array().of(yup.string()),
  others: yup.array().of(yup.string()),
});

const Form = ({
  draftProfile, incrementForm, isFinalForm, tags,
}) => {
  const {tracker} = useAnalytics();

  if (!tags) return null; // Loading tags

  const handleSubmit = (update) => {
    const {companies, others} = update;
    tracker.submitOnboarding({step: 'companies', data: update});
    const cleanedOtherValues = others.map((o) => o.trim().toLowerCase());
    incrementForm({companies: _.union(companies, cleanedOtherValues)});
  };

  let knownCompanies;
  let others;
  if (draftProfile.companies) {
    [knownCompanies, others] = _.partition(
      draftProfile.companies,
      (c) => tags?.companies?.includes(c),
    );
  }
  const initialValues = {
    companies: knownCompanies || [],
    others: others || [],
    query: '',
  };

  return (
    <div className="w-full max-w-2xl mt-6 mb-40 sm:mt-12">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Schema}
      >
        <FormikForm>
          <CompanyFields tags={tags} name="companies" />
          <SubmitButton>{isFinalForm ? 'Submit' : 'Continue'}</SubmitButton>
        </FormikForm>
      </Formik>
    </div>
  );
};

export default {
  key,
  title,
  Schema,
  Fields: CompanyFields,
  Form,
};
