import React from 'react';
import cx from 'classnames';
import _ from 'lodash';

import {XIcon} from './svg';

const CloseButton = ({className = '', onClick = _.noop, disabled = false}) => (
  <button
    type="button"
    aria-label="Close"
    className={cx(`
      text-gray-300
      focus-within:text-gray-400
      hover:text-gray-400
      `,
    className)}
    onClick={onClick}
    disabled={disabled}
  >
    <XIcon className="block h-full w-full fill-current transition-all" />
  </button>
);

export default CloseButton;
