const Feature = {
  Coaching: 'coaching',
  Courses: 'courses',
  Profile: 'profile',
  Questions: 'questions',
  Referrals: 'referrals',
  Guides: 'guides',
};

module.exports = {
  Feature,
};
