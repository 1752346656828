import React from 'react';
import {useFormikContext} from 'formik';

import Button, {ButtonSizes} from '../ButtonNew';

const SubmitButton = (props) => {
  const {errors, isSubmitting} = useFormikContext();
  return (
    <div className="fixed left-0 bottom-0 w-full bg-white p-3 border-t shadow md:shadow-none md:p-0 md:relative md:w-auto flex justify-end mt-8">
      <div className="flex flex-col w-full">
        <Button
          size={ButtonSizes.LARGE}
          className="w-full"
          disabled={isSubmitting || Object.keys(errors).length > 0}
          type="submit"
          {...props}
        />
        <span className="mt-1 text-center font-medium text-red-500">{errors?.roles}</span>
      </div>
    </div>
  );
};

export default SubmitButton;
