import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {
  Field, FieldArray, useField, ErrorMessage,
} from 'formik';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OfficeBuildingIcon} from '@heroicons/react/solid';

import Colors from '../constants/colors';
import CloseButton from './CloseButton';
import {Searcher} from '../../../lib/fuzzySearch';

const INITIAL_SHOWN = 12;
const PAGES_SIZE = 24;

const CompanyCheckbox = ({company, name}) => {
  const [, {value}, {setValue}] = useField(name);

  const checked = value.includes(company.id);

  const handleClick = () => {
    if (checked) {
      setValue(value.filter((v) => v !== company.id));
    } else {
      setValue([...value, company.id]);
    }
  };

  return (
    <label className="flex flex-col items-center w-28" htmlFor={company.id}>
      <button
        type="button"
        onClick={handleClick}
        className={cx('relative w-12 h-12 mb-2 flex justify-center items-center border border-gray-300 rounded-full', {'border-indigo': checked})}
      >
        {checked && <FontAwesomeIcon className="absolute top-0 right-0 border border-white rounded-full bg-white" icon={faCheckCircle} color={Colors.Purple} />}
        {company.image_url ? (
          <img
            className="h-6 w-6 object-contain"
            alt={company.name}
            src={company.image_url}
          />
        ) : (
          <OfficeBuildingIcon className="h-6 text-gray-400" />
        )}
      </button>
      <div className={cx('font-medium text-center text-gray-500', {'font-semibold text-indigo': checked})}>
        {company.name}
      </div>
    </label>
  );
};

const SearchBar = ({showAddButton, placeholder}) => {
  const [, {value: others}, {setValue: setOthers}] = useField('others');
  const [, {value: query}, {setValue: setQuery}] = useField('query');

  const addOtherCompany = () => {
    if (query && query.trim()) {
      setQuery('');
      setOthers([...others, query]);
    }
  };

  return (
    <label className="w-full font-normal mb-10" htmlFor="others">
      <FieldArray name="others">
        {({remove}) => (
          <>
            <div className="w-full relative flex items-center">
              <Field
                name="query"
                placeholder={placeholder || 'Filter...'}
                className="w-full px-6 py-4 border border-gray-300 rounded-lg"
              />
              {showAddButton && (
              <button
                className="absolute right-5 text-indigo font-bold"
                type="button"
                onClick={addOtherCompany}
              >
                {`Add "${query}"`}
              </button>
              )}
            </div>
            <div className="flex flex-wrap mt-4">
              {others.map((company, i) => <Pill company={company} onClick={() => remove(i)} />)}
            </div>
          </>
        )}
      </FieldArray>
    </label>
  );
};

const Pill = ({company, onClick}) => (
  <div
    key={company}
    className={`
        flex
        justify-center
        items-center border
        border-indigo
        text-indigo
        font-semibold
        rounded-full
        capitalize
        px-3
        mr-2
        mb-2
        h-8
        leading-8`}
  >
    {company}
    <CloseButton
      className={`
          ml-1.5
          h-4
          w-4
          text-gray-400
          focus-within:text-gray-500
          hover:text-gray-500`}
      onClick={onClick}
    />
  </div>
);

const CompanyFields = ({tags, name, placeholder}) => {
  const [pagesShown, setPagesShown] = useState(0);
  const [fuzzySearcher, setFuzzySearcher] = useState(null);
  const [fewResults, setFewResults] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [, {value: selectedCompanies}] = useField(name);
  const [, {value: query}] = useField('query');

  useEffect(() => {
    let searcher = fuzzySearcher;
    if (!searcher) {
      searcher = new Searcher(tags.company, ['name'], {
        threshold: 0.2, // Set lower than elsewhere, so that fewResults flag works decently
      });
      setFuzzySearcher(searcher);
    }

    const [selected, notSelected] = _.partition(
      tags.company,
      (company) => selectedCompanies.includes(company.id),
    );

    searcher.setCollection(notSelected);
    const filtered = _.map(searcher.search(query), 'item');

    setCompanies([...selected, ...filtered]);
    setFewResults(query && filtered.length < 4);
  }, [query]);

  const numShown = INITIAL_SHOWN + pagesShown * PAGES_SIZE;

  return (
    <div className="w-full mb-8">
      <SearchBar showAddButton={fewResults} placeholder={placeholder} />
      <div
        className="grid gap-2 grid-cols-3 xs:grid-cols-4 sm:grid-cols-6"
        role="group"
        aria-labelledby="checkbox-group"
      >
        {companies.map((company, i) => (i < numShown
            && <CompanyCheckbox company={company} name={name} key={company.id} />
        ))}
      </div>
      {companies.length > numShown && (
      <div className="w-full flex justify-center mt-6">
        <button
          className="text-indigo text-base font-bold cursor-pointer"
          onClick={() => setPagesShown((num) => num + 1)}
          type="button"
        >
          Show more
        </button>
      </div>
      )}

      <ErrorMessage name={name} />
    </div>
  );
};

export default CompanyFields;
