import React, {useEffect, useState} from 'react';
import {Formik, Form as FormikForm, useField} from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import cx from 'classnames';

import SubmitButton from './SubmitButton';
import {useAnalytics} from '../../hooks';
import {StyledTextInput as TextInput} from '../Forms';

import {Searcher} from '../../../../lib/fuzzySearch';

const RolesToIcons = {
  // 'swe': '💻',
  // 'pm': '📦',
  // 'data-science': '📊',
};

const key = 'roles';
const title = 'What role are you preparing for?';
const subtitle = (
  <span>
    We&rsquo;ll give you personalized advice for the role you select.
    <br className="hidden sm:block" />
    {' '}
    Don&rsquo;t worry, you can always change this later.
  </span>
);

const Schema = yup.object().shape({
  roles: yup.array().of(yup.string()).length(1, 'Please select a role'),
});

const RoleCheckbox = ({role, icon}) => {
  const [, {value}, {setValue}] = useField(key);
  const checked = value.includes(role.id);

  const handleClick = () => {
    if (checked) {
      setValue(value.filter((v) => v !== role.id));
    } else {
      setValue([role.id]);
    }
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cx('flex bg-white font-medium py-2 px-4 border rounded-full cursor-pointer', {
        'border-indigo text-indigo': checked,
        'border-gray-300 hover:bg-gray-50': !checked,
      })}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {role.name}
    </button>
  );
};

const MAX_ROLES_SHOWN = 16;

const Fields = ({tags}) => {
  const rolesByCategory = _.groupBy(tags.role, 'parent_tag');
  const [filter, setFilter] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [fuzzySearcher, setFuzzySearcher] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    const setUpSearcher = async () => {
      const searcher = new Searcher(tags.role, ['name', 'id']); // Filter by name and shorter IDs
      setFuzzySearcher(searcher);
      setFilteredResults(searcher.search());
    };
    setUpSearcher();
  }, []);

  const filteredRoles = filteredResults.map((result) => result.item);

  const handleFilter = (e) => {
    setFilter(e.target.value);
    setFilteredResults(fuzzySearcher.search(e.target.value));
  };

  return (
    <div className="">
      <TextInput
        className="mb-4"
        value={filter}
        onChange={handleFilter}
        placeholder="Filter for roles..."
      />
      <div className="flex flex-wrap items-center gap-x-2 gap-y-3">
        {filteredRoles.slice(0, showAll ? filteredRoles.length : MAX_ROLES_SHOWN).map((role) => (
          <RoleCheckbox role={role} key={role.id} icon={RolesToIcons[role.id]} />
        ))}
        {filteredRoles.length > MAX_ROLES_SHOWN && !showAll && (
          <div className="flex justify-center items-center mt-0.5 mx-3">
            <button
              className="flex items-center text-indigo font-semibold cursor-pointer"
              onClick={() => setShowAll(true)}
              type="button"
            >
              Show all roles...
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Form = ({
  draftProfile, incrementForm, isFinalForm, tags,
}) => {
  const {tracker} = useAnalytics();

  const initialValues = {
    [key]: draftProfile[key] || [],
  };

  const handleSubmit = (update) => {
    tracker.submitOnboarding({step: 'roles', data: update});
    incrementForm(update);
  };

  if (!tags) return null; // Loading tags

  return (
    <div className="w-full max-w-xl mb-40 mt-6 sm:mt-12">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Schema}
      >
        <FormikForm>
          <Fields tags={tags} />
          <SubmitButton>{isFinalForm ? 'Submit' : 'Continue'}</SubmitButton>
        </FormikForm>
      </Formik>
    </div>
  );
};

export default {
  key,
  title,
  subtitle,
  Schema,
  Fields,
  Form,
};
