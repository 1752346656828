/* eslint-disable no-bitwise */
const _ = require('lodash');

/**
 * Hashes strings into 32-bit numbers. Useful for turning uuids into seeds for
 * unique-names-generator. Does NOT preserve uniqueness!
 *
 * See more: https://stackoverflow.com/a/7616484/3681279
 * @param {string} str
 */
const hashCode = (str) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Takes a tag coming from Airtable and formats it to match its id in our
 * tag table (e.g. 'PayPal' to 'paypal', 'Goldman Sachs' to 'goldman-sachs')
 */
const airtableTagToDbTag = (tag) => _.kebabCase(tag.toLowerCase());

/**
 * Takes a tag coming from the db and attempts to format it to match its use
 * in Airtable (e.g. 'google' to 'Google',  'goldman-sachs' to 'Goldman Sachs')
 * NOTE: Fails for cases like 'youtube' which is stored in Airtable as 'YouTube'
 */
const dbTagToAirtableTag = (tag) => _.startCase(tag);

/**
 * Take an array of role tags and returns an array with those same tags
 * and their parent_tags, with duplicate values removed.
 */
const withParentTags = (selectedTags, tags) => _(selectedTags)
  .flatMap((selectedTag) => {
    const tag = tags?.role?.find(({id}) => id === selectedTag);
    return tag ? [tag.id, tag.parent_tag] : [];
  })
  .compact()
  .uniq()
  .value();

const roleToCategories = {
  'account-manager': ['behavioral'],
  'backend-engineer': ['algorithms', 'data-structures', 'system-design'],
  bizops: ['behavioral'],
  'business-analyst': ['behavioral'],
  'business-development': ['behavioral'],
  'chief-of-staff': ['behavioral'],
  'customer-success-manager': ['behavioral'],
  'data-analyst': ['analytical'],
  'data-engineer': ['algorithms', 'data-structures'],
  'data-science': ['analytical'],
  'design-manager': ['behavioral', 'product-design'],
  devops: ['system-design'],
  em: ['system-design'],
  'finance-strategy': ['behavioral'],
  'frontend-engineer': ['algorithms', 'data-structures'],
  'growth-marketing-manager': ['behavioral'],
  'hardware-engineer': ['system-design'],
  'investment-banking': ['behavioral'],
  'management-consultant': ['behavioral', 'execution'],
  'marketing-manager': ['behavioral'],
  'ml-engineer': ['algorithms', 'data-structures'],
  'mobile-engineer': ['algorithms', 'data-structures', 'system-design'],
  pm: ['analytical', 'product-strategy'],
  pmm: ['behavioral'],
  'private-equity': ['behavioral'],
  'product-analyst': ['product-design'],
  'product-designer': ['product-design'],
  'qa-engineer': ['system-design'],
  recruiter: ['behavioral'],
  'sales-representative': ['behavioral'],
  security: ['system-design'],
  'solutions-architect': ['system-design'],
  sre: ['system-design'],
  swe: ['algorithms', 'data-structures', 'system-design'],
  'test-engineer': ['algorithms', 'data-structures'],
  tpm: ['program-sense'],
  'user-researcher': ['product-design'],
  'venture-capital': ['estimation'],
  'visual-designer': ['product-design'],
};

/**
 * Takes a role tag id and returns an array of related type tag ids
 */
const toRelatedCategories = (role) => roleToCategories[role];

/**
 * Collection of all activities that contribute to a user's karma score.
 * Must include id and label. Should include value, but note these can
 * sometimes be variable (like in the case of MockInterviewCompleted).
 */
const KarmaValues = {
  QuestionPublished: {
    id: 'question-published',
    value: 25,
    label: 'Your interview question is published',
    href: '/questions/contribute?src=karma',
  },

  QuestionUpvoted: {
    id: 'question-upvoted',
    value: 5,
    label: 'Your interview question is upvoted',
    href: '/questions/contribute?src=karma',
  },

  // QuestionDownvoted: {
  //   id: 'question-downvoted',
  //   value: 0,
  //   label: 'Your interview question is downvoted',
  //   href: '/questions/contribute?src=karma',
  // },

  QuestionSeenElsewhere: {
    id: 'question-seen-elsewhere',
    value: 5,
    label: 'Your question appears in another interview',
    href: '/questions?src=karma',
  },

  CommentUpvoted: {
    id: 'comment-upvoted',
    value: 5,
    label: 'Your comment is upvoted',
    href: '/questions?src=karma',
  },

  // CommentDownvoted: {
  //   id: 'comment-downvoted',
  //   value: 0,
  //   label: 'Your comment is downvoted',
  //   href: '/questions?src=karma',
  // },

  // CorrectCodeEditorSubmission: {
  //   id: 'correct-code-editor-submission',
  //   value: 0,
  //   label: 'You submit a correct answer in the code editor',
  //   href: '/courses/swe-practice?src=karma',
  // },

  MockInterviewJoined: {
    id: 'mock-interview-joined',
    value: 10,
    label: 'You join a mock interview',
    href: '/practice?src=karma',
  },

  MockInterviewPositiveFeedback: {
    id: 'mock-interview-positive-feedback',
    value: 15,
    label: 'You are a great mock interview partner',
    description: 'Earn 15 additional karma when your mock interview partner rates you highly',
    href: '/practice?src=karma',
  },

  LessonCompleted: {
    id: 'lesson-completed',
    value: 1,
    label: 'You complete a lesson',
    href: '/courses?src=karma',
  },


  ProfileCompleted: {
    id: 'profile-completed',
    value: 10,
    label: 'You fill out your profile',
    href: '/account/profile?src=karma',
  },

  UserReferred: {
    id: 'user-referred',
    value: 100,
    label: 'You refer someone to Exponent',
    href: '/referrals?src=karma',
  },
};

module.exports = {
  hashCode,
  airtableTagToDbTag,
  dbTagToAirtableTag,
  withParentTags,
  toRelatedCategories,
  KarmaValues,
};
