import React from 'react';
import {Formik, Form as FormikForm, Field} from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import cx from 'classnames';

import SubmitButton from './SubmitButton';
import {useAnalytics} from '../../hooks';

const key = 'career_progress';
const title = 'I need help with...';

const choices = [
  {
    key: 'progress_applying',
    label: 'Finding job opportunities',
  },
  {
    key: 'progress_getting_interview',
    label: 'Passing resume screens',
  },
  {
    key: 'progress_interviewing',
    label: 'Acing my interviews',
  },
  {
    key: 'progress_negotiating',
    label: 'Negotiating my offers',
  },
  {
    key: 'progress_starting',
    label: 'Starting my new job',
  },
  {
    key: 'progress_mentorship',
    label: 'Getting mentorship',
  },
  {
    key: 'progress_exploring',
    label: 'Exploring new careers',
  },
];

const Schema = yup.object().shape({
  career_progress: yup.array().of(yup.string()),
});

const StyledCheckbox = ({field, ...props}) => {
  const checked = field.value.includes(props.id);
  return (
    <>
      <input
        type="checkbox"
        {...field}
        {...props}
        className={cx(
          'relative w-6 h-6 m-0 mr-5 border border-gray-300 bg-gray-50 rounded ease-in duration-200 cursor-pointer outline-none appearance-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          {'bg-indigo border-indigo': checked},
        )}
      />
      <span className={cx('absolute left-1.5 text-white select-none', {hidden: !checked})}>✓</span>
    </>
  );
};

const Fields = () => (
  <div role="group" aria-labelledby="checkbox-group">
    {choices.map((choice) => (
      <label className="relative flex items-center mb-6 cursor-pointer" htmlFor={choice.key} key={choice.key}>
        <Field
          id={choice.key}
          name={key}
          value={choice.key}
          component={StyledCheckbox}
        />
        {choice.label}
      </label>
    ))}
  </div>
);

const Form = ({
  draftProfile, incrementForm, isFinalForm,
}) => {
  const {tracker} = useAnalytics();

  const initialValues = {
    [key]: draftProfile[key] || [],
  };

  const handleSubmit = (update) => {
    tracker.submitOnboarding({step: 'career_progress', data: update});
    incrementForm(update);
  };

  return (
    <div className="w-full max-w-sm mt-6 mb-40 sm:mt-12">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Schema}
      >
        <FormikForm>
          <Fields />
          <SubmitButton>{isFinalForm ? 'Submit' : 'Continue'}</SubmitButton>
        </FormikForm>
      </Formik>
    </div>
  );
};

export default {
  key,
  title,
  Schema,
  Fields,
  Form,
};
